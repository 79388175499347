import { Text, Container } from "@mantine/core";
import React, { useEffect } from "react";

const VisitorVisa = () => {
  return (
    <div>
      <div className="relative w-full h-1/2">
        <img
          style={{ height: "650px" }}
          className="w-full object-cover brightness-75"
          src="/visitor_visa_hero.jpg"
          alt="Visitor travelling to canada with luggage"
        />

        <div
          style={{ transform: "translate(-50%, -50%)" }}
          data-aos-delay="300"
          data-aos="fade-up"
          className="absolute left-1/2 top-1/2 text-6xl font-bold text-white"
        >
          Visitor Visa
        </div>
      </div>

      <Container size={1400}>
        <div>
          <div className="text-5xl pt-20"></div>

          <div className="flex gap-20">
            <div className="w-full md:w-1/2">
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                A visitor visa is a type of
                <b> temporary resident visa </b>
                that allows foreign nationals to visit Canada for a temporary
                purpose, such as tourism, visiting family or friends, or
                attending business meetings. The visitor visa is typically valid
                for
                <b> up to 6 months</b>, but can be issued for longer periods in
                certain circumstances.
              </Text>

              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                To apply for a visitor visa, you will need to provide various
                documents, such as a valid passport, proof of financial support,
                and a travel itinerary. You may also need to undergo a
                <b> medical examination</b> or provide
                <b> a police certificate</b>, depending on your country of
                citizenship and the duration of your stay.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                Once you have been granted a visitor visa, you will need to
                comply with certain conditions, such as not working or studying
                in Canada unless authorized to do so. You may also need to show
                <b> proof of a return ticket </b>
                or <b> sufficient funds </b> to leave Canada at the end of your
                visit.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                It's important to note that a visitor visa does not guarantee
                entry into Canada. Border officials will assess your
                <b> admissibility </b>
                at the time of entry and may refuse entry if they determine that
                you do not meet the requirements or pose a risk to Canadian
                security or safety.
              </Text>

              <br />
            </div>
            <img
              data-aos="fade-up"
              data-aos-duration="1500"
              className="w-1/2 object-cover hidden md:block"
              src="/visitor_visa_main.jpg"
              alt="Toronto Canada"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VisitorVisa;
