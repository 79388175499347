"use client";
import React from "react";
import Header from "../components/Header.comp";
import Footer from "../components/Footer.comp";

const Layout = ({ children }) => {
  return (
    <div>
      <Header
        links={[
          { link: "Home", label: "Home" },
          { link: "Services", label: "Services" },
          { link: "Aboutus", label: "About Us" },
          { link: "Contactus", label: "Contact Us" },
        ]}
      />
      {children}

      <Footer data={[]} />
    </div>
  );
};

export default Layout;
