import {
  createStyles,
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  Container,
  ActionIcon,
  Notification,
  Modal,
} from "@mantine/core";
import emailjs from "@emailjs/browser";
import {
  FaYoutubeSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaFacebookSquare,
} from "react-icons/fa";
import useCustomStyles from "../styles";
import { useRef, useState } from "react";
import { BiCheck } from "react-icons/bi";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: "border-box",

    backgroundImage: `linear-gradient(-60deg, #000 0%, #333 100%)`,
    borderRadius: theme.radius.md,
    padding: `2rem`,

    [theme.fn.smallerThan("sm")]: {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: "300px",

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    "&:hover": {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const social = [
  { icon: FaYoutubeSquare, link: "" },
  {
    icon: FaInstagramSquare,
    link: "https://instagram.com/navarsh_immigration/",
  },
  {
    icon: FaFacebookSquare,
    link: "https://www.facebook.com/navarshImmigration",
  },
];

const Contact = () => {
  const form = useRef();
  const { classes } = useStyles();
  const { classes: customClasses } = useCustomStyles();
  const [modalShow, setModalShow] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_JS_PUBLIC
      )
      .then(
        (result) => {
          console.log(result);
          setModalShow(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const icons = social.map((Icon, index) => (
    <ActionIcon
      key={index}
      size={28}
      component="a"
      href={Icon.link}
      className={classes.social}
      variant="transparent"
    >
      <Icon.icon size="1.4rem" stroke={1.5} />
    </ActionIcon>
  ));

  return (
    <section id="Contactus">
      <Container size={1400} mt="7rem">
        <div className={classes.wrapper}>
          <SimpleGrid
            cols={2}
            spacing={50}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <div>
              <Title className={classes.title}>Contact us</Title>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                className={classes.description}
                mt="sm"
                mb={10}
              >
                Leave your email and we will get back to you within 24 hours
              </Text>
              <Text
                mt="xs"
                data-aos="fade-up"
                data-aos-duration="1000"
                className={classes.description}
              >
                Contact Us: +1 (289)-981-1430
              </Text>

              <Text
                mt="xs"
                data-aos="fade-up"
                data-aos-duration="1000"
                className={classes.description}
              >
                Email: info@navarshimmigration.com
              </Text>
              <Text
                mt="xs"
                data-aos="fade-up"
                data-aos-duration="1000"
                className={classes.description}
              >
                Address: 31 Fairlight Street, Brampton, L6Z 3W2, ON, Canada
              </Text>

              <Group data-aos="fade-up" data-aos-duration="1000" mt="xl">
                {icons}
              </Group>
            </div>
            <form ref={form} onSubmit={sendEmail} className={classes.form}>
              <TextInput
                label="Email"
                placeholder="your@email.com"
                name="from_name"
                required
                type="email"
                classNames={{ input: classes.input, label: classes.inputLabel }}
              />
              <TextInput
                label="Name"
                placeholder="Your Name"
                name="name"
                mt="md"
                classNames={{ input: classes.input, label: classes.inputLabel }}
              />
              <Textarea
                required
                name="message"
                label="Your message"
                placeholder={"I want to apply for..."}
                minRows={4}
                mt="md"
                classNames={{ input: classes.input, label: classes.inputLabel }}
              />

              <Group position="right" mt="md">
                <Button
                  type={"submit"}
                  className={customClasses.primary_control}
                >
                  Send message
                </Button>
              </Group>
            </form>

            {modalShow && (
              <Modal
                styles={{ header: { fontSize: "1.4rem" } }}
                centered
                opened={modalShow}
                onClose={() => setModalShow(false)}
                size="auto"
                title="Email Send"
              >
                <Text>
                  {" "}
                  Thanks. A Consultant will respond to your inquiry soon.
                </Text>
              </Modal>
            )}
          </SimpleGrid>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
