import { Text, Container } from "@mantine/core";
import React, { useEffect } from "react";

const SkilledWorkerVisa = () => {
  return (
    <div>
      <div className="relative w-full h-1/2">
        <div
          style={{
            background: "url('/Skilled_Worker_program_hero.jpg')",
            height: "650px",
            backgroundPosition: "bottom",
            backgroundSize: "cover",
          }}
          className="brightness-75 w-full"
        ></div>

        <div
          style={{ transform: "translate(-50%, -50%)" }}
          data-aos-delay="300"
          data-aos="fade-up"
          className="absolute left-1/2 top-1/2 text-6xl font-bold text-white"
        >
          Skilled Worker Visa
        </div>
      </div>

      <Container size={1400}>
        <div>
          <div className="text-5xl pt-20"></div>

          <div className="flex gap-20">
            <div className="w-full md:w-1/2 ">
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                Canada has several immigration programs that skilled workers can
                apply for, depending on their qualifications, work experience,
                and other factors. Express entry is the platform for such
                workers to enter Canada. An Electronic
                <b> Comprehensive Ranking System (CRS) </b>
                is used to create pool of qualified candidates based on several
                criteria - age, education, language, skills, work experience and
                others. In order to get an accurate score in CRS and become
                eligible, it is imperative not to misrepresent yourself and try
                to enhance the scoring only through valid means, for example:
                retaking the a language test or gaining more work experience.
              </Text>

              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                Top ranking candidates receive an ITA after which it is
                mandatory to express the willingness to accept it, As a final
                step, an application for PR is filed which is further assessed
                by Canadian authorities.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <Text color="dark">
                  Here are some of the most popular programs:
                </Text>
                <br />
                <b>Federal Skilled Worker Program (FSWP): </b>
                This program is designed for skilled workers who want to
                immigrate to Canada permanently. Applicants must meet the
                eligibility requirements, which include language proficiency,
                education, work experience, age, and adaptability. Applicants
                are scored based on a points system, and those with the highest
                scores are invited to apply for permanent residence.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Provincial Nominee Programs (PNPs): </b>
                Each province in Canada has its own immigration program for
                skilled workers, known as a PNP. These programs are designed to
                meet the specific labor market needs of each province, and they
                have different eligibility criteria and application processes.
                Applicants must first apply to the province where they want to
                live and work, and if they are nominated, they can apply for
                permanent residence through the federal government.
              </Text>
            </div>
            <img
              data-aos="fade-up"
              data-aos-duration="1500"
              className="w-1/2 object-cover hidden md:block"
              src="/Skilled_Worker_Program_main.jpg"
              alt="Skilled Worker Program"
            />
          </div>
        </div>

        <div className="flex gap-20 mt-32 ">
          <img
            data-aos="fade-up"
            data-aos-duration="1500"
            className="hidden md:block w-1/2 object-cover"
            src="/canada_girl.jpg"
            alt="Girl in Alberta with canadian flag"
          />
          <div className="w-full md:w-1/2">
            <Text
              data-aos="fade-up"
              data-aos-duration="1000"
              color="dimmed"
              mt="md"
              size={"xl"}
            >
              <b>Canadian Experience Class (CEC): </b>
              This program is designed for skilled workers who have already
              worked in Canada for at least one year in a skilled occupation.
              Applicants must meet the eligibility requirements, which include
              language proficiency, work experience, and education. Unlike the
              FSWP, there is no requirement for a job offer.
            </Text>

            <Text
              data-aos="fade-up"
              data-aos-duration="1000"
              color="dimmed"
              mt="md"
              size={"xl"}
            >
              <b>The Federal Skilled Trades Program (FSTP): </b>
              It is a Canadian immigration program designed for skilled workers
              in specific trade occupations. The program aims to address the
              shortage of skilled workers in the Canadian labour market.
            </Text>
            <Text
              data-aos="fade-up"
              data-aos-duration="1000"
              color="dimmed"
              mt="md"
              size={"xl"}
            >
              These are just some of the immigration programs available to
              skilled workers in Canada. It is important to research each
              program carefully to determine which one is the best fit for your
              qualifications and goals. So, without further delay contact us for
              the best guidance.
            </Text>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SkilledWorkerVisa;
