import { Text, Container } from "@mantine/core";
import React, { useEffect } from "react";

const PNP = () => {
  return (
    <div>
      <div className="relative w-full h-1/2">
        <img
          style={{ height: "650px" }}
          className="w-full object-cover brightness-75"
          src="/PNP_program_hero.jpg"
          alt="Fall colors"
        />

        <div
          style={{ transform: "translate(-50%, -50%)" }}
          data-aos-delay="300"
          data-aos="fade-up"
          className="absolute md:whitespace-nowrap left-1/2 top-1/2 text-6xl font-bold text-white"
        >
          Provincial Nominee Program (PNP){" "}
        </div>
      </div>

      <Container size={1400}>
        <div>
          <div className="text-5xl pt-20"></div>

          <div className="flex gap-20">
            <div className="w-full md:w-1/2">
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                PNP stands for Provincial Nominee Program, which is a Canadian
                immigration program that allows a province or territory in
                Canada to nominate individuals who wish to immigrate to Canada
                and settle in a specific province or territory.
              </Text>

              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                Each province or territory has its own PNP with unique criteria
                and requirements. The program is designed to help{" "}
                <b>fill labor market gaps </b> and address specific economic and
                social needs of the province or territory.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                To be eligible for a PNP, an individual must have the skills,
                education, and work experience needed to make a contribution to
                the economy of the province or territory. They must also meet
                the eligibility requirements of the specific PNP they are
                applying to.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                If an individual is nominated by a province or territory through
                their PNP, they can then apply to{" "}
                <b>Immigration, Refugees and Citizenship Canada (IRCC) </b> for
                permanent residence. Being nominated by a province or territory
                can increase an individual's chances of being invited to apply
                for permanent residence through one of the federal immigration
                programs, such as <b>Express Entry</b>.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                PNP program is based on a bilateral agreement between federal
                and provincial government. Provinces issue a "Certificate of
                Nomination" to qualified candidates who then apply for Permanent
                Residentship through IRCC and undergo assessment of
                inadmissibility by a <b> visa officer </b> who has final
                authority to decide on a PNP application.
              </Text>
            </div>
            <img
              data-aos="fade-up"
              data-aos-duration="1500"
              className="w-1/2 hidden md:block object-cover"
              src="/PNP_flags_main.jpg"
              alt="Canada Provinces Flags"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PNP;
