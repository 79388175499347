import { Text, Container } from "@mantine/core";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import StudyPermit from "./Services/StudyPermit.page";
import PermanentResidency from "./Services/PNP";
import FamilyVisa from "./Services/FamilyVisa.page";
import SkilledWorkerVisa from "./Services/SkilledWorkerVisa";
// import TemporaryResidentVisa from "./Services/TemporaryResidentVisa";
import Citizenship from "./Services/Citizenship";
// import TemporaryWorkVisa from "./Services/TemporaryWorkVisa";
import BusinessVisa from "./Services/BusinessVisa";
import PNP from "./Services/PNP";
import VisitorVisa from "./Services/VisitorVisa";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { pathname } = useLocation();

  const getPage = (path) => {
    const splitPath = path.split("/");
    const lastPath = splitPath[splitPath.length - 1];

    const obj = {
      StudentVisa: <StudyPermit />,
      ProvincialNomineeprogram: <PNP />,
      FamilyVisa: <FamilyVisa />,
      SkilledWorkerVisa: <SkilledWorkerVisa />,
      BusinessVisa: <BusinessVisa />,
      Citizenship: <Citizenship />,
      VisitorVisa: <VisitorVisa />,
    };

    const chosePage = obj[lastPath];
    return chosePage;
  };

  return getPage(pathname);
};

export default Service;
