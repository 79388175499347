import React from 'react'
import HeroComp from "../components/Hero.comp";
import Features from "../components/Features.comp";
import About from "../components/About.comp";
import Contact from '../components/Contact.comp';

const Home = () => {
  return (
	<>
	<HeroComp />
	<Features />
	<About/>
	<Contact/>
	</>	
  )
}

export default Home