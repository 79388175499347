import {  Switch, Route, withRouter, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home.page'
import Service from './pages/Service.page';
import './styles/globals.css'
import Layout from './components/Layout.comp';
import { QueryClient, QueryClientProvider } from 'react-query'
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import UnderConstruction from './pages/UnderConstruction.page';
import ScrollToTop from './components/Scroll.comp';

const queryClient = new QueryClient()

function App() {
	useEffect(() => {
		AOS.init()
	}, [])


  return (
	<BrowserRouter>
	 <QueryClientProvider client={queryClient}>
		 <Switch>
			<Layout>
		 		{/* <Route exact path="/" component={(UnderConstruction)}/> */}
			<ScrollToTop/>
		  <Route exact path="/" component={(Home)} />
          <Route path="/services/:name" component={(Service)} /> 
			</Layout>
        </Switch>
	 </QueryClientProvider>
	</BrowserRouter>
  );
}

export default App;
