import { Text, Container } from "@mantine/core";
import React, { useEffect } from "react";

const StudyPermit = () => {
  return (
    <div>
      <div className="relative w-full h-1/2">
        <img
          style={{ height: "650px" }}
          className="w-full object-cover brightness-75"
          src="/student_permit_hero.jpg"
          alt="Student Graduation"
        />

        <div
          style={{ transform: "translate(-50%, -50%)" }}
          data-aos-delay="300"
          data-aos="fade-up"
          className="absolute left-1/2 top-1/2 text-6xl font-bold text-white"
        >
          Study Permit
        </div>
      </div>

      <Container size={1400}>
        <div>
          <div className="text-5xl pt-20"></div>

          <div className="flex gap-20">
            <div className="w-full md:w-1/2">
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                A Canadian study permit is a document issued by the Government
                of Canada that allows foreign nationals to study in Canada at
                <b> Designated Learning Institutions (DLIs). </b>
                It is also known as a Canadian student visa.
              </Text>

              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                To obtain a study permit, an international student must first be
                accepted by a DLI and provide <b>proof of their acceptance, </b>{" "}
                as well as evidence of sufficient funds to cover their tuition
                fees, living expenses and return transportation. They may also
                be required to provide a <b>police certificate </b>
                and undergo
                <b> a medical examination.</b>
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                Once issued, the study permit will specify the length of time
                the student is authorized to study in Canada, as well as any
                restrictions on their ability to work while studying. It is
                important to note that a study permit is not a travel document
                and does not guarantee entry into Canada. International students
                may also be required to obtain a visitor visa or an electronic
                travel authorization (eTA) in addition to their study permit,
                depending on their country of citizenship.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                After finishing studies in Canada, one is eligible for Post
                Graduate Work Permit(PGWP) which helps to further extend the
                stay and ultimately obtain Permanent Residence. Besides PGWP,
                other programs also allow to gain eligibility to work and get
                Canadian PR:
              </Text>

              <br />

              <Text color="dimmed" size={"xl"}>
                <ul className="list-disc list-inside">
                  <li>Express Entry</li>
                  <li>PNP</li>
                  <li>CEC</li>
                  <li>FSWP</li>
                  <li>FSTP</li>
                </ul>
              </Text>
            </div>
            <img
              data-aos="fade-up"
              data-aos-duration="1500"
              className="w-1/2 object-cover hidden md:block"
              src="/student_permit_main.jpg"
              alt="Student graduated in canada"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default StudyPermit;
