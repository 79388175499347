import { useEffect, useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const HEADER_HEIGHT = 70;

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
    background: "#000",
    marginBottom: "0px !important",
    borderBottomColor: "black",
    position: "sticky",
    top: 0,
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",
    background: "black",
    border: "none",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    borderBottomColor: "black !important",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    background: "black !important",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: "white",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: "white",
      color: "black",
    },
  },
}));

const HeaderResponsive = ({ links }) => {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();
  const { hash, pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (hash) setActive(hash.substring(1));

    const getPathName = pathname.substring(1).split("/")[0];

    if (getPathName === "services") setActive("Services");
  }, [hash, pathname]);

  const items = links.map((link) => (
    <a
      key={link.link}
      href={`#${link.link}`}
      className={cx(classes.link, {
        [classes.linkActive]: active === link.link,
      })}
      onClick={(event) => {
        event.preventDefault();
        history.push(`/#${link.link}`);
        close();
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <Header height={HEADER_HEIGHT} mb={120} className={classes.root}>
      <Container size={1400} className={classes.header}>
        <div className="flex items-center ">
          <Link to="/">
            <img
              src="/yellow_logo.png"
              alt="Navarsh Immigration Logo"
              className="w-40 md:w-52"
            />
          </Link>
        </div>

        <Group spacing={5} className={classes.links}>
          {items}
          <a href="https://wa.me/1289981430?text=Hi, I need your guidance with immigration">
            <img className="w-7 ml-3" src="/whatsapp.png" alt="Whatsapp Icon" />
          </a>
        </Group>

        <Burger
          opened={opened}
          onClick={toggle}
          color="white"
          className={classes.burger}
          size="sm"
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
};

export default HeaderResponsive;
