import {
  createStyles,
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
} from "@mantine/core";
import useCustomStyles from "../styles/index";
import { AiOutlineArrowDown } from "react-icons/ai";

const HeroComp = () => {
  const { classes } = useCustomStyles();

  const scrollDown = (event, name) => {
    event.preventDefault();

    let element = document.getElementById(name);
    const yOffset = -70;

    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <div className="video_height" id="Home">
      {/* style={{ background: "#111", height: "53vh", clipPath: 'polygon(0 0, 100% 0%, 100% 94%, 0 86%)' }} */}
      <div className="video-wrapper">
        <video src="/navarsh_intro.mp4" autoPlay muted loop playsinline />
        <div
          className="absolute left-1/2 top-1/2 w-full md:w-2/5 "
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <div
            data-aos-delay="300"
            data-aos="fade-up"
            className="text-3xl md:text-5xl leading-12 text-center font-bold text-white drop-shadow-sm"
          >
            We are providing Professional Immigration Solutions
          </div>

          <div className="flex items-center  gap-3 md:gap-10 justify-center my-4 flex-col md:flex-row">
            <Button
              data-aos-delay="700"
              data-aos="fade-up"
              className={`${classes.secondary_control} py-2`}
              onClick={(event) => scrollDown(event, "Services")}
            >
              Our Services
            </Button>
            <Button
              data-aos-delay="1000"
              data-aos="fade-up"
              className={`${classes.primary_control} py-2`}
              onClick={(event) => scrollDown(event, "Contactus")}
            >
              Get Free Consultation
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroComp;
