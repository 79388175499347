import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const ScrollToTop = () => {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    console.log("pathname", pathname, hash);

    if (hash) {
      const element = document.querySelector(hash);

      const yOffset = -70;

      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [pathname, hash]);
};

export default ScrollToTop;
