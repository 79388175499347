import {
	createStyles,
	
  } from '@mantine/core';

  const useCustomStyles = createStyles((theme) => ({
	inner: {
	  display: 'flex',
	  justifyContent: 'space-between',
	  paddingTop: theme.spacing.xl * 4,
	  paddingBottom: theme.spacing.xl * 4,
	},
  
	content: {
	  maxWidth: 480,
	  marginRight: theme.spacing.xl * 3,
  
	  [theme.fn.smallerThan('md')]: {
		maxWidth: '100%',
		marginRight: 0,
	  },
	},
  
	title: {
	  color: "white",
	  fontSize: 44,
	  fontFamily: 'Faustina',
	  lineHeight: 1.2,
	  fontWeight: 900,
  
	  [theme.fn.smallerThan('xs')]: {
		fontSize: 28,
	  },
	},
  
	primary_control: {
		background: "#ffdd00 !important",
		color: "black",

	  [theme.fn.smallerThan('xs')]: {
		flex: 1,
	  },

	  '&:hover': {
		backgroundColor: 'black !important',
		color: '#ffdd00'

	  },
	},

	secondary_control:{
		background: "white !important",
		color: "black",

	  [theme.fn.smallerThan('xs')]: {
		flex: 1,
	  },

	  '&:hover': {
		backgroundColor: 'black !important',
		color: "white",

	  },
	},

	tertiary_control: {
		background: "black !important",
		color: "#FFDD00",

	  [theme.fn.smallerThan('xs')]: {
		flex: 1,
	  },

	  '&:hover': {
		background: '#FFDD00 !important',
		color: 'black'
	  },
	},


  
	image: {
	  flex: 1,
  
	  [theme.fn.smallerThan('md')]: {
		display: 'none',
	  },
	},
  
	highlight: {
	  position: 'relative',
	  backgroundColor: '#222',
	  color: "white",
	  borderRadius: theme.radius.sm,
	  padding: '2px 4px',
	},
  }));

  export default useCustomStyles;