import { Text, Container } from "@mantine/core";
import React, { useEffect } from "react";

const Citizenship = () => {
  return (
    <div>
      <div className="relative w-full h-1/2">
        <img
          style={{ height: "650px" }}
          className="w-full object-cover brightness-75"
          src="/citizenship_hero.jpg"
          alt="Application for canadian citizenship"
        />

        <div
          style={{ transform: "translate(-50%, -50%)" }}
          data-aos-delay="300"
          data-aos="fade-up"
          className="absolute left-1/2 top-1/2 text-6xl font-bold text-white"
        >
          Citizenship
        </div>
      </div>

      <Container size={1400}>
        <div>
          <div className="text-5xl pt-20"></div>

          <div className="flex gap-20">
            <div className="w-full md:w-1/2">
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                To become a Canadian citizen, you must first be a permanent
                resident of Canada for
                <b>
                  {" "}
                  at least three years (1,095 days) out of the last five years{" "}
                </b>
                . You must also meet other eligibility requirements, such as
                passing a language and citizenship test, and not having any
                serious criminal convictions.
              </Text>

              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                Once you meet the eligibility requirements, you can apply for
                Canadian citizenship by filling out an application and paying
                the required fees. The application will then be reviewed by the
                government, and if approved, you will be invited to attend a
                citizenship ceremony, where you will take the Oath of
                Citizenship and receive your certificate of citizenship.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                Canadian citizens have <b>special constitutional privilege</b>{" "}
                to become a member of Parliament or Provincial Parliament and
                accorded with constitutional
                <b> right to vote </b>
                in Federal and Provincial elections. They are also entitled to
                the right to apply for a<b> Canadian passport </b>
                which allows a citizen to return to Canada any time after
                travelling or living abroad.
              </Text>

              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                It's important to note that dual citizenship is allowed in
                Canada, so applicants do not have to give up their previous
                citizenship (depending on their countries' rules and
                regulations) when they become Canadian citizens. However, they
                must also follow the laws and regulations of their other country
                of citizenship.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                Overall, Canadian citizenship offers many benefits, including
                access to a strong economy, a diverse and welcoming society, and
                a high standard of living.
              </Text>
            </div>
            <img
              data-aos="fade-up"
              data-aos-duration="1500"
              className="w-1/2 object-cover hidden md:block"
              src="/canada_girl.jpg"
              alt="canadian girl with canada flag"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Citizenship;
