import { Text, Container } from "@mantine/core";
import React, { useEffect } from "react";

const FamilyVisa = () => {
  return (
    <div>
      <div className="relative w-full h-1/2">
        <div
          style={{
            background: "url('/Family_visa_hero.jpg')",
            height: "650px",
            backgroundPosition: "10% 72%",
            backgroundSize: "cover",
          }}
          className="brightness-75 w-full"
        ></div>

        <div
          style={{ transform: "translate(-50%, -50%)" }}
          data-aos-delay="300"
          data-aos="fade-up"
          className="absolute left-1/2 top-1/2 text-6xl font-bold text-white"
        >
          Family Visa
        </div>
      </div>

      <Container size={1400}>
        <div>
          <div className="text-5xl pt-20"> </div>

          <div className="flex gap-20">
            <div className="w-full md:w-1/2">
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                The Family Class Visa is a program offered by the Canadian
                government that allows Canadian citizens and permanent residents
                to sponsor their family members to come and live with them in
                Canada. Here is an overview of the different types of Family
                Class Visas available:
              </Text>

              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Spousal Sponsorship: </b>
                This program allows Canadian citizens and permanent residents to
                sponsor their spouse or common-law partner to come and live with
                them in Canada. To qualify, the sponsor must be at least 18
                years old and in a genuine relationship with their partner.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Dependent Child Sponsorship: </b>
                This program allows Canadian citizens and permanent residents to
                sponsor their dependent children to come and live with them in
                Canada. To qualify, the child must be under 22 years of age and
                not be married or in a common-law relationship.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Parent and Grandparent Sponsorship: </b>
                This program allows Canadian citizens and permanent residents to
                sponsor their parents and grandparents to come and live with
                them in Canada. To qualify, the sponsor must meet certain income
                requirements and commit to supporting their family member
                financially for a specified period.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                The sponsor enters into an undertaking for a specific time which
                acts as a legal binding to provide basic living expenses of the
                family members who are sponsored.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                The duration of undertaking differs for various relationships:
                <ul className="list-disc">
                  <li>Spouse - 3 Years</li>
                  <li>Parents and Grandparent - 20 Years</li>
                  <li>Others - 10 Years</li>
                </ul>
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Super Visa: </b>
                It is a special category created to replace lengthy Sponsorship
                process so that family members (PGP) come together faster. One
                specific feature of this category is that the applicant
                undergoes a medical examination and buys canadian medical
                insurance for atleast 1 year.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                Overall, the Family Class Visa program is designed to help keep
                families together and facilitate reunification for Canadian
                citizens and permanent residents.
              </Text>
            </div>
            <img
              data-aos="fade-up"
              data-aos-duration="1500"
              className="w-1/2 object-cover hidden md:block"
              src="/family_visa_main.jpg"
              alt="Family in Canada"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FamilyVisa;
