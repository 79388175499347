import React from "react";
import {
  createStyles,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
} from "@mantine/core";
//   import Image from 'next/image'
import { AiOutlineArrowDown } from "react-icons/ai";
import useCustomStyles from "../styles/index";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 90,
    paddingBottom: 90,
  },

  content: {
    marginRight: theme.spacing.xl * 3,
    textAlign: "left",
  },

  title: {
    color: "black",
    fontSize: 44,
    fontFamily: "Lato",
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: 28,
    },
  },

  image: {
    flex: 1,
  },

  highlight: {
    position: "relative",
    backgroundColor: "#222",
    color: "white",
    borderRadius: theme.radius.sm,
    padding: "2px 4px",
  },
}));

const About = () => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCustomStyles();

  return (
    <div id="Aboutus" style={{ background: "white" }}>
      <Container size={1400}>
        <Title className={classes.title}>
          About <span className={classes.highlight}> Us</span> <br />
        </Title>
        <div className={`${classes.inner} flex-col lg:flex-row py-5`}>
          <div
            className={`${classes.content} w-full lg:w-4/6 order-2 lg:order-1`}
          >
            <Text
              data-aos="fade-up"
              data-aos-duration="1000"
              color="dimmed"
              mt="lg"
            >
              Welcome to our immigration consultancy based in Brampton! We are a
              team of dedicated professionals who are passionate about helping
              individuals and families navigate the complex immigration process
              to Canada. Our mission is to provide personalized and
              comprehensive services to our clients to ensure their successful
              migration to Canada.
            </Text>

            <Text
              data-aos="fade-up"
              data-aos-duration="1000"
              color="dimmed"
              mt="md"
            >
              At our consultancy, we understand that immigrating to a new
              country can be a daunting and overwhelming experience. That's why
              we strive to provide our clients with the guidance and support
              they need to make the transition as smooth as possible. Whether
              you are seeking temporary or permanent residency, our experienced
              consultants will work with you to determine the best path forward
              based on your unique circumstances. Our team of consultants is
              comprised of knowledgeable and experienced individuals who are
              well-versed in Canadian immigration law and policies. We stay
              up-to-date with the latest developments and changes in immigration
              regulations to ensure that our clients receive the most accurate
              and relevant information.
            </Text>

            <Text
              data-aos="fade-up"
              data-aos-duration="1000"
              color="dimmed"
              mt="md"
            >
              Our services include visa applications, permanent residency
              applications, citizenship applications, and more. At our
              consultancy, we are committed to providing exceptional customer
              service. We understand the importance of communication and strive
              to keep our clients informed and involved throughout the entire
              process. We take pride in our attention to detail and personalized
              approach, and we are dedicated to helping our clients achieve
              their immigration goals. Thank you for considering our consultancy
              as your trusted partner in your immigration journey. We look
              forward to working with you and helping you achieve your dreams of
              living and thriving in Canada.
            </Text>

            <Text
              data-aos="fade-up"
              data-aos-duration="1000"
              color="dimmed"
              mt="lg"
            >
              Our founder and CEO Ruby is a senior professional having more than
              15 years of experience in handling various international trade,
              compliance and customer oriented profiles. Her meticulous and
              ethical approach will turn your application into success.
            </Text>
            <div
              className="md:flex justify-between"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src="RCIC_logo.png" alt="RCIC_logo" className="w-54 h-28" />
              <img
                alt="Ruby Consultant"
                src="Ruby_sign_with_deg.png"
                className="w-48"
              />
            </div>

          </div>

          <img
            data-aos="fade-up"
            data-aos-duration="1500"
            className="lg:w-2/6 w-full  object-cover md:order-1"
            src="/R_JAIN2.jpg"
            alt="Ruby CEO"
          />
        </div>
      </Container>
    </div>
  );
};

export default About;
