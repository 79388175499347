import { Text, Container } from "@mantine/core";
import React, { useEffect } from "react";

const BusinessVisa = () => {
  return (
    <div>
      <div className="relative w-full h-1/2">
        <img
          style={{ height: "650px" }}
          className="w-full object-cover brightness-75"
          src="/business_visa_hero.jpg"
          alt="Canada building"
        />

        <div
          style={{ transform: "translate(-50%, -50%)" }}
          data-aos-delay="300"
          data-aos="fade-up"
          className="absolute left-1/2 top-1/2 text-6xl font-bold text-white"
        >
          Business Visa
        </div>
      </div>

      <Container size={1400}>
        <div>
          <div className="text-5xl pt-20"></div>

          <div className="flex gap-20">
            <div className="w-full md:w-1/2">
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size={"xl"}
              >
                A business visa for Canada is a Temporary Resident Visa (TRV).
                It allows you to enter Canada for a temporary period for
                business purposes, such as attending conferences, meetings, and
                negotiations. To obtain a business visa for Canada, you will
                need to follow these steps:
              </Text>

              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Determine if you need a visa: </b>
                Citizens of certain countries are exempt from the visa
                requirement and can enter Canada with an Electronic Travel
                Authorization (eTA). You can check if your country is eligible
                for an eTA on the Canadian government website.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Complete the application form: </b>
                You can complete the application form online or on paper. You
                will need to provide personal information, passport details, and
                information about your trip to Canada.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Provide supporting documents: </b>
                You will need to provide several documents, such as a valid
                passport, proof of financial support, and a letter of invitation
                from a Canadian business or organization.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Pay the application fee: </b>
                The fee for a business visa is CAD 160. You can pay online or at
                a visa application center.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Submit your application: </b>
                You can submit your application online or in person at a visa
                application center.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                <b>Wait for a decision: </b>
                The processing time for a business visa can vary depending on
                the visa office and your individual circumstances. You can check
                the processing times on the Canadian government website.
              </Text>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                color="dimmed"
                mt="md"
                size="xl"
              >
                If your application is approved, you will receive a{" "}
                <b>letter of introduction </b> which you will need to present to
                the border officer when you arrive in Canada. The officer will
                then issue your business visa, which will indicate the duration
                of your stay in Canada.
              </Text>
            </div>
            <img
              data-aos="fade-up"
              data-aos-duration="1500"
              className="w-1/2 object-cover hidden md:block"
              src="/business_visa_main.jpg"
              alt="Handshake"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BusinessVisa;
