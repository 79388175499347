import { AiOutlineArrowRight } from "react-icons/ai";
import {
  Card,
  Image,
  Text,
  Group,
  Badge,
  Button,
  ActionIcon,
  createStyles,
} from "@mantine/core";
import useCustomStyles from "../styles/index";
import { Link, NavLink } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  card: {
    // width: "300px",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  card_image: {
    height: "200px",
    width: "100%",
    objectFit: "cover",

    [theme.fn.smallerThan("lg")]: {
      //   width: "100%",
    },
  },

  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },

  like: {
    color: theme.colors.red[6],
  },

  label: {
    textTransform: "uppercase",
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}));

const BadgeCard = ({ image, title, description, country, badges }) => {
  const { classes, theme } = useStyles();
  const { classes: customStyles } = useCustomStyles();

  return (
    <Card withBorder radius="lg" p="md" className={`${classes.card}`}>
      <Card.Section>
        <img className={classes.card_image} src={image} alt={title} />
      </Card.Section>

      <Card.Section className={classes.section} mt="md">
        <Group position="apart">
          <Text size="lg" weight={500}>
            {title}
          </Text>
        </Group>
        <Text align="left" lineClamp={2} size="sm" mt="xs">
          {description}
        </Text>
      </Card.Section>

      <Group mt="xs">
        <NavLink
          className="flex w-full"
          to={`/services/${title.split(" ").join("")}`}
        >
          <Button
            className={customStyles.tertiary_control}
            radius="md"
            style={{ flex: 1 }}
          >
            <p className="flex">
              Read More <AiOutlineArrowRight size={18} />
            </p>
          </Button>
        </NavLink>
      </Group>
    </Card>
  );
};

export default BadgeCard;
