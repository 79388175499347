import {
  createStyles,
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
  Anchor,
  Group,
  Container,
} from "@mantine/core";
import { AiOutlineBank } from "react-icons/ai";
import BadgeCard from "./Card.comp";
import useCustomStyles from "../styles/index";
import {
  motion,
  AnimatePresence,
  AnimateSharedLayout,
  LayoutGroup,
} from "framer-motion";
import { useState } from "react";
import "../styles/CustomCard.css";

const VISA = [
  {
    title: "Skilled Worker Visa",
    desc: "A platform for skilled workers to enter Canada under various categories.",
    badge: "",
    image: "Skilled_Worker_program_hero.jpg",
  },
  {
    title: "Business Visa",
    desc: "Business class travels to Canada temporarily on account of tour or vacation, visit to family and business visits",
    badge: "",
    image: "business_visa_hero.jpg",
  },
  {
    title: "Family Visa",
    desc: "The Family Class Visa is a program offered by the Canadian government that allows Canadian citizens and permanent residents",
    badge: "",
    image: "Family_visa_hero.jpg",
  },
  {
    title: "Student Visa",
    desc: "A Canadian study permit is a document issued by the Government of Canada that allows foreign nationals to study in Canada at Designated Learning Institutions (DLIs).",
    badge: "",
    image: "student_permit_hero.jpg",
  },
  {
    title: "Citizenship",
    desc: "To become a Canadian citizen, you must first be a permanent resident of Canada for at least three years (1,095 days) out of the last five years .",
    badge: "",
    image: "citizenship_hero.jpg",
  },
  {
    title: "Provincial Nominee program",
    desc: "PNP stands for Provincial Nominee Program, which is a Canadian immigration program that allows a province or territory in Canada to nominate individuals who wish to immigrate to Canada",
    badge: "",
    image: "PNP_flags_main.jpg",
  },
  {
    title: "Visitor Visa",
    desc: " A visitor visa is a type of temporary resident visa that allows foreign nationals to visit Canada for a temporary purpose, such as tourism, visiting family or friends, or attending business meetings. The visitor visa is typically valid for",
    badge: "",
    image: "visitor_visa_hero.jpg",
  },
];

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: "white",
    width: "29%",
    borderRadius: "1rem",

    [theme.fn.smallerThan("lg")]: {
      width: "42%",
    },
    [theme.fn.smallerThan("md")]: {
      width: "42%",
    },
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
    },
  },

  section: {
    background: "black",
    color: "white",
    paddingTop: "4rems",
    marginTop: "0rem",
    clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 86%)",
    paddingBottom: "20rem",

    [theme.fn.smallerThan("md")]: {
      clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 96%)",
      paddingBottom: "10rem",
    },
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    height: 90,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: `black !important`,
      transform: "scale(1.05)",
    },
  },
}));

const ActionsGrid = () => {
  const { classes } = useStyles();
  const [selectedId, setSelectedId] = useState(null);

  return (
    <section className={classes.section} id="Services">
      <Container size={1400}>
        <div className="text-4xl pt-10 font-semibold mb-5">Our Services</div>
        <Text mb={"lg"} color="#ffdd00" size={"lg"}>
          Navarsh Immigration Team works on the principle "Your Success our
          Commitment". We offer all kinds of immigration solutions through our
          services, few of which are:
        </Text>
        <div className="flex flex-wrap gap-16">
          {VISA.map((e, index) => {
            return (
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
                key={index}
                className={classes.card}
              >
                <BadgeCard
                  title={e.title}
                  description={e.desc}
                  country={e.badge}
                  image={e.image}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default ActionsGrid;
